import React, { useState } from 'react';
import styles from './LanguageSelector.module.css';

const LanguageSelector = ({ onLanguageChange }) => {
  const [targetLang, setTargetLang] = useState('English');

  const handleChange = (e) => {
    const newLang = e.target.value;
    setTargetLang(newLang);
    onLanguageChange(newLang);
  };

  return (
    <div className={styles.languageSelector}>
      <label htmlFor="language-select" className={styles.languageSelectorLabel}>Select Language:</label>
      <select
        id="language-select"
        className={styles.dropdown}
        value={targetLang}
        onChange={handleChange}
      >
        <option value="English">English</option>
        <option value="Korean">Korean</option>
        <option value="Japanese">Japanese</option>
        <option value="Vietnamese">Vietnamese</option>
        <option value="Spanish">Spanish</option>
        <option value="French">French</option>
        <option value="German">German</option>
        <option value="Hindi">Hindi</option>
        <option value="Urdu">Urdu</option>
        <option value="Portuguese">Portuguese</option>
        <option value="Chinese">Chinese</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
