import React, { useState, useEffect } from 'react';
import Chat from './Chat';
import Trait from './Trait';
import Note from './Note';
import Modal from './Modal';  // Import the Modal component
import LanguageSelector from './LanguageSelector'; // Import the LanguageSelector component
import styles from './App.module.css';
import ShowPersona from './ShowPersona';
import DropdownMenu from './DropdownMenu';
import logo from './logo.png'; // Import the image
import elogo from './elogo.png'; // Import the image

function App() {
  const [persona, setPersona] = useState('');
  const [personas, setPersonas] = useState('');
  const [selectedMemberIndex, setSelectedMemberIndex] = useState();
  const [currentPersona, setCurrentPersona] = useState(null);
  const [currentName, setCurrentName] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);  // State to track the selected note
  const [speaklang, setSpeakLang] = useState('English'); // State to track the selected language

  const handleTextChange = (text) => {
    //setPersona(text);
  };

  const epochTimeInSeconds = Math.floor(new Date().getTime() / 1000);

  const [config, setConfig] = useState(null);
  useEffect(() => {
    fetch(`/config.json?${epochTimeInSeconds}`)
      .then(response => response.json())
      .then(data => {
        setConfig(data.main);
        setPersonas(data['main'].experts);
        //console.log(data['main'].experts); // Process your data here
      })
      .catch(error => {
        console.error('Error fetching config.json:', error);
      });
  }, []);

  const handleSelectedMemberChange = (index) => {
    setSelectedMemberIndex(index);
    setCurrentName(personas.staff[index].name);
    setCurrentAvatar(personas.staff[index].avatar);
    let name = personas.staff[index].name;
    let blurb = personas.staff[index].blurb;
    let pbackground = personas.staff[index].background;
    let persona = personas.staff[index].persona;
    let communication_style = personas.staff[index].communication_style;
    let quirks = personas.staff[index].quirks;
    let influence = personas.staff[index].influence;
    let teaching_style = personas.staff[index].teaching_style;
    let jargon_examples = personas.staff[index].jargon_examples;
    let jargon_amount = personas.staff[index].jargon;
    let guidance = config.guidance;
    let ask = config.eco.ask;
    let profile = `${ask}: ${name} is ${blurb} whose background is ${pbackground} this is at the core of who they are ${persona}. Their influences that shape who they are and how they respond are ${influence}. The way they like to respond is ${communication_style}. Their personality quirks are ${quirks}. The type of jargon they will use in their response ${jargon_amount} of the time includes ${jargon_examples}, but the jargon can be variations of this. ${guidance}.`
    //` Return the response starting with <i>${name}</i> –`;

    if (speaklang !== "English") {
        profile = `Return the response in ${speaklang}, as if a native speaker of ${speaklang} ` + profile;
    }
  console.log("hi" ,speaklang)
    setCurrentPersona(profile);
    setPersona(profile);
  };

  const addNote = (note) => {
    setNotes(prevNotes => [...prevNotes, note]);
  };

  const removeNote = (id) => {
    setNotes(prevNotes => prevNotes.filter(note => note.id !== id));
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
  };

  const handleCloseModal = () => {
    setSelectedNote(null);
  };

  const handleLanguageChange = (lang) => {
    setSpeakLang(lang);
    //handleSelectedMemberChange(selectedMemberIndex);
  };

  useEffect(() => {
  if (selectedMemberIndex!=null) {
  //  console.log("aaaaaaa", speaklang)
    handleSelectedMemberChange(selectedMemberIndex);
  }
}, [speaklang]);

  return (
    <div className={styles.app}>
      <div className={styles.header}><img src={elogo} className={styles.elogo} alt="logo" /></div>
      <div className={styles.main}>
        <div className={`${styles.column} ${styles.left}`}>
        {!  <DropdownMenu />}
          <div className={styles.hide}><Trait onTextChange={handleTextChange} /></div>
          {config && (
            <ShowPersona
              config={config}
              personas={personas}
              onSelectedMemberChange={handleSelectedMemberChange}
              currentAvatar={currentAvatar}
            />
          )}
          <LanguageSelector onLanguageChange={handleLanguageChange} /> {/* Add the LanguageSelector component */}
        </div>
        <div className={`${styles.column} ${styles.center}`}>
          <Chat
            persona={persona}
            currentName={currentName}
            currentAvatar={currentAvatar}
            addNote={addNote}
            removeNote={removeNote}  // Pass the removeNote function
          />
        </div>
        <div className={`${styles.column} ${styles.right}`}>
          <div className={styles.coltitle}>Notes</div>
          <div className={styles.notes}>
            {notes.map((note, index) => (
              <Note
                key={index}
                question={note.question}
                response={note.response}
                avatar={note.avatar}
                onClick={() => handleNoteClick(note)}  // Set the selected note on click
              />
            ))}
          </div>
        </div>
      </div>
      {selectedNote && (
        <Modal
          question={selectedNote.question}
          response={selectedNote.response}
          onClose={handleCloseModal}
        />
      )}
      <div className={styles.footer}> <img src={logo} className={styles.logo} alt="logo" /></div>
    </div>
  );
}

export default App;
