import React from 'react';
import styles from './DropdownMenu.module.css'; // Make sure to import your CSS file

const DropdownMenu = () => {
  return (
    <div className={styles.dropdown}>
      <button className={styles.dropbtn}>Switch EGOS</button>
      <div className={styles['dropdown-content']}>
        <a href="#eco-experts">Eco Experts</a>
        <a href="#gen-alpha-group">Gen-Alpha Group</a>
        <a href="#gen-z-group">Gen-Z Group</a>
        <a href="#strat-experts">Strat Experts</a>
        <a href="#tech-perspective">Tech Perspective</a>
      </div>
    </div>
  );
}

export default DropdownMenu;
