import React from 'react';
import styles from './Note.module.css';

function Note({ question, response, onClick, avatar }) {
    return (
        <div className={styles.note} onClick={onClick}>
            <p>
                <strong>Q:</strong> <span dangerouslySetInnerHTML={{ __html: question.substring(0, 20) + '...' }} />
            </p>
            <p className={styles.answer}>
                <strong>A:</strong> <span dangerouslySetInnerHTML={{ __html: response.substring(0, 40) + '...' }} />
                <img src={avatar} alt="Avatar" className={styles.avatar} />
            </p>
        </div>
    );
}

export default Note;
